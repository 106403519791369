import { Link } from 'gatsby'
import * as React from 'react'
import MainLayout from '../layouts/main-layout'
import interstellarGIF from '../images/404.gif'

// markup
const NotFoundPage = () => {
  return (
    <MainLayout>
      <h1>404</h1>
      <h2>You are no longer within the known universe.</h2>
      <Link style={{display: 'flex', marginBottom: '3.2rem'}} to="/">Let&apos;s go home.</Link>
      <img src={interstellarGIF} alt="Mankind's next step will be our greatest." />
    </MainLayout>
  )
}

export default NotFoundPage
